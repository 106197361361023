export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

//// fix for utc: https://github.com/Hacker0x01/react-datepicker/issues/1787
export const convertLocalToUTCDate = (date) => {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}
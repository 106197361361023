import React from 'react'
import '../css/Footer.css'

function Footer() {
    return (
        <div className='footer'>
            <div className='footerInner'>
                <div className='footerInnerLeft'>
                    <div>
                        <p style={{ fontWeight: 'bold', marginBottom: '5px' }} >Book Direct Go</p>
                        <span>A perfect holiday experience with memories.</span>
                        <br />
                        <span>Plan a reasonable stay for you. </span>
                    </div>
                </div>
                {/* <p>Privacy · Terms · Sitemap · Company Details</p> */}
                <div className='footerInnerRight'>
                    <p  style={{ fontWeight: 'bold', marginBottom: '5px' }} >Contacts</p>
                    <span>Agias Paraskevis 14</span>
                    <span>Thessaloniki, Greece</span>
                    <span style={{ marginBottom: '5px' }} >561 22</span>
                    <span>Phone:&nbsp;&nbsp;&nbsp;
                        <a style={{ color: 'white', textDecoration: 'none' }} href="tel:+306992324720" target="_blank" ><span >+30-699-232-4720</span></a>
                    </span>
                    <span>Viber:&nbsp;&nbsp;&nbsp;
                        <a style={{ color: 'white', textDecoration: 'none' }} href='viber://add?number=306992324720' target="_blank"  ><span >+30-699-232-4720</span></a>
                    </span>
                    <span>WhatsApp:&nbsp;&nbsp;&nbsp;
                        <a style={{ color: 'white', textDecoration: 'none' }} href='https://api.whatsapp.com/send?phone=306992324720' target="_blank" ><span >+30-699-232-4720</span></a>
                    </span>
                    {/* <span style={{ textDecoration: 'underline' }}>
                        <a href='viber://add?number=306992324720' style={{ color: '#ff7f00' }}>Viber</a>
                    </span>
                    <span style={{ textDecoration: 'text-decoration: underline' }}>
                        <a href='https://api.whatsapp.com/send?phone=306992324720' style={{ color: '#ff7f00' }}>WhatsApp</a>
                    </span> */}
                    {/* <p>© 2020 Plivik corp. All rights reserved!</p> */}
                </div>
            </div>
        </div>
    )
}

export default Footer

// //// https://ibaslogic.com/routing-with-react-router/  ---(LINK - как перейти на другую страницу с передачей данных)
// import React from "react";
// import { useLocation } from 'react-router-dom';

// const TestSecond=(props)=> {
//     const location = useLocation();
//     const data = location.state;
//     console.log(data);

//     return (
//         <div>
//             {data && (
//                 <div>
//                     <h4>Test Second COmponent</h4>
//                     <h4>{data.name}</h4>
//                     <button onClick={() => console.log(data)}>MyBUt</button>
//                 </div>
//             )}
//         </div>
//     )
// }

// export default TestSecond



// import React, { useEffect } from 'react';

// const TestSecond = () => {
//   useEffect(() => {
//     const handleMessage = (event) => {
//       console.log(event.data);
//     };

//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };
//   }, []);

//   return (
//     <div>
//       <h1>New Window</h1>
//     </div>
//   );
// };

// export default TestSecond


//// Пример открытие нового окна с передачей данных через local Storage
import React, { useState } from "react";

class TestSecond extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            mytext: ""
        }
        this.getData = this.getData.bind(this);
    }
    //mytext= "Text by Default";
    

    //// событие по окончании загрузки компонента
    componentWillMount() {
        //// Элементу присваиваем значение из local Storage
        const myvariable=localStorage.getItem("pageOneData");
        this.setState({
            mytext: myvariable
        }
            , () => {
                console.log('dddddd: ', this.state.mytext)                
            }
        );
        //// удаление элемента из local Storage (после того как получили данные)
        localStorage.removeItem("pageOneData");
    }

    getData() {
                
        const myvariable=localStorage.getItem("pageOneData");
        console.log(myvariable);
        this.setState({
            mytext: myvariable
        }
            , () => {
                console.log('dddddd: ', this.state.mytext)                
            }
        );
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row col-md-4">
                        <h1>New Window with data</h1>
                        <h1>{this.state.mytext}</h1>
                        <button type="button" onClick={this.getData}>Get Data</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TestSecond

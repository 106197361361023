// https://www.youtube.com/watch?v=BGKbJ2aXCog&list=PL6DxKON1uLOHcvuJLmgpOhwbktXLr7noj&index=2
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HeaderHead from "./headear components/HeaderHead";
import MaskedPhoneInput from "./MaskedPhoneInput";
// import '../css/TouristInfo.css'


let nameInput = React.createRef();
let emailInput = React.createRef();
let phoneInput = React.createRef();
let messageInput = React.createRef();



const ValidationMy = () => {
    const [email, setEmail] = useState('')
    const [firstLastNames, setFirstLastNames] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [emailDirty, setEmailDirty] = useState(false)
    const [firstLastNamesDirty, setFirstLastNamesDirty] = useState(false)
    const [phoneNumberDirty, setPhoneNumberDirty] = useState(false)

    const [emailError, setEmailError] = useState('Please enter a valid email address')
    const [firstLastNamesError, setFirstLastNamesError] = useState('Please enter your First and Last names using Latin characters')
    const [phoneNumberError, setPhoneNumberError] = useState('Please enter correct phone number with country code')

    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        if (emailError || firstLastNamesError || phoneNumberError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [emailError, firstLastNamesError, phoneNumberError])

    const BlurHandler = (e) => {
        switch (e.target.name) {
            case 'user_email':
                setEmailDirty(true)
                break
            case 'name':
                setFirstLastNamesDirty(true)
                break
            case 'phoneNumber':
                setPhoneNumberDirty(true)
                break
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        // if (e.target.value.length < 1) {
        //     setEmailError('Email field cannot be empty')
        // } else 
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Please enter a valid email address')
        } else {
            setEmailError('')
        }
    }

    // const firstLastNamesHandler = (e) => {
    //     setFirstLastNames(e.target.value)
    //     if (e.target.value.length < 3 || e.target.value.length > 8) {
    //         setFirstLastNamesError('The FirstLastNames must be from 3 to 8 symbols')
    //         if (!e.target.value) {
    //             setFirstLastNamesError('FirstLastNames cannot be empty')
    //         }
    //     } else {
    //         setFirstLastNamesError('')
    //     }
    // }

    const firstLastNamesHandler = (e) => {
        setFirstLastNames(e.target.value)
        // const re = /^[a-z ,.'-]+$/i;
        const re = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/;
        // if (e.target.value.length < 1) {
        //     setFirstLastNamesError('This field cannot be empty!')
        // }else if (e.target.value.length < 4) {
        //     setFirstLastNamesError('Please enter both your First and Last Names using Latin characters!')
        // } else 
        if (!re.test(String(e.target.value).toLowerCase())) {
            setFirstLastNamesError('Please enter your First and Last names using Latin characters')
        } else {
            setFirstLastNamesError('')
        }
    }

    const phoneNumberHandler = (e) => {
        setPhoneNumber(e.target.value)        
        const re = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setPhoneNumberError('Please enter correct phone number with country code')
        } else {
            setPhoneNumberError('')
        }
    }


    const onClickAction = () => {
        // !formValid ? console.log('Not Valid') : console.log('The button is clicked')
        if (!formValid) {
            setEmailDirty(true)
            setFirstLastNamesDirty(true)
            setPhoneNumberDirty(true)
            //console.log('Not Valid')
        } else {
            //console.log('The button is clicked')
        }
    }

    return (
        <div>
            <h1>Registration</h1>
            <div className="formInputs">
                <form className="formInputsInner"
                // style={{ margin: '15px 20px 0px 0px' }}
                // onSubmit={this.SendEmail}
                >
                    <label>Name and Surname*</label>
                    {(firstLastNamesDirty && firstLastNamesError) && <div style={{ color: 'red' }}>{firstLastNamesError}</div>}
                    <input
                        name="name"
                        type='text'
                        ref={nameInput}
                        placeholder='Name and surname'
                        onChange={e => firstLastNamesHandler(e)} onBlur={e => BlurHandler(e)}></input><br />

                    <label>Email address*</label>
                    {(emailDirty && emailError) && <div style={{ color: 'red' }}>{emailError}</div>}
                    <input type='text' name="user_email" ref={emailInput} placeholder='Email address'
                        onChange={e => emailHandler(e)} value={email} onBlur={e => BlurHandler(e)} ></input><br />

                    <label>Phone number (with country code)*</label>
                    {(phoneNumberDirty && phoneNumberError) && <div style={{ color: 'red' }}>{phoneNumberError}</div>}                    
                    <input 
                    name="phoneNumber"
                    type='text' 
                    ref={phoneInput} 
                    placeholder='Phone number (with country code)'
                    onChange={e => phoneNumberHandler(e)} onBlur={e => BlurHandler(e)}></input><br />

                    <label>Message</label>
                    <textarea name="message" ref={messageInput} placeholder='You can add some additional info or questions' />

                </form>
                <button
                    //  disabled={!formValid}
                    //  value={firstLastNames}
                    type='submit'
                    onClick={() => onClickAction()
                    }
                >
                    Registrtion
                </button>
            </div>
        </div>
    )
}

export default ValidationMy
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HeaderHead from "./headear components/HeaderHead";
// import '../css/TouristInfo.css'


const ValidationSimple = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailDirty, setEmailDirty] = useState(false)
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [emailError, setEmailError] = useState('Email cannot be empty')
    const [passwordError, setPasswordError] = useState('Password cannot be empty')
    const [formValid, setFormValid]=useState(false)

    useEffect(()=>{
        if(emailError || passwordError){
            setFormValid(false)
        } else{
            setFormValid(true)
        }
    },[emailError, passwordError])

    const BlurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true)
                break
            case 'password':
                setPasswordDirty(true)
                break
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('The email you provided is not correct')
        } else {
            setEmailError('')
        }
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        if (e.target.value.length < 3 || e.target.value.length > 8) {
            setPasswordError('The Password must be from 3 to 8 symbols')
            if(!e.target.value){
                setPasswordError('Password cannot be empty')
            }
        } else {
            setPasswordError('')
        }
    }

    return (
        <div>
            <form>
                <h1>Resistration</h1>
                {(emailDirty && emailError) && <div style={{ color: 'red' }}>{emailError}</div>}
                <input onChange={e => emailHandler(e)} value={email} onBlur={e => BlurHandler(e)} name='email' type='text' placeholder="Enter your email...." />
                
                {(passwordDirty && passwordError) && <div style={{ color: 'red' }}>{passwordError}</div>}
                <input onChange={e => passwordHandler(e)} onBlur={e => BlurHandler(e)} name='password' type='password' placeholder="Enter your password...." />
                
                <button disabled={!formValid} value={password} type='submit'>Registrtion</button>

            </form>
        </div>
    )
}

export default ValidationSimple
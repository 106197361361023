import React from "react"
import "../css/Header.css";
/* import Banner02 from "../images/banner.jpg"; */
import DateRangePickerShahabyazdi from "./headear components/DateRangePickerShahabyazdi";
import HeaderHead from "./headear components/HeaderHead";
import AddGuests from "./headear components/AddGuests";
import axios from "axios";
import { addDays, convertLocalToUTCDate } from "./ProgectFunctions";
import Banner02 from "../images/Banner02.jpg";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rdrpStartDate: null, //addDays(new Date(),3), //new Date("2021-08-01"), //null,
            rdrpEndDate: null, //new Date("2021-08-09"), //null,

            gstNumOfAdults: 2,
            gstNumOfChildren: 0,
            gstStringAges: "99,99",

            prces: [],
            location: 'Greece',

            tesvariable: ''
        };

        this.getDataFromDateRangePicker = this.getDataFromDateRangePicker.bind(this);
        this.getDataFromAddGuests = this.getDataFromAddGuests.bind(this);
        this.clearGotDataOnChange = this.clearGotDataOnChange.bind(this);
        this.setLocation = this.setLocation.bind(this);

        this.handleSubmitFirst = this.handleSubmitFirst.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        /* this.setStateExample = this.setStateExample.bind(this); */ /* для примера */
    }

    setLocation(e) {
        e.preventDefault();
        this.setState({ location: e.target.value }); //// здесь  обнуляю таблмцу с результатами поиска
        //// послаем данные родителю
        // this.props.func(null);
    }

    getDataFromDateRangePicker(startDate, endDate) {
        this.setState({ rdrpStartDate: startDate, rdrpEndDate: endDate });
    }

    getDataFromAddGuests(numOfAdults, numOfChild, stringAges) {
        this.setState({
            gstNumOfAdults: numOfAdults,
            gstNumOfChildren: numOfChild,
            gstStringAges: stringAges,
        });
    }


    clearGotDataOnChange() {
        this.setState({ prces: [] }); //// здесь  обнуляю таблмцу с результатами поиска
        //// послаем данные родителю
        this.props.func(null);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.clearGotDataOnChange();

        const srchparams = {
            place: e.target.Place.value,

            ArrDate: this.state.rdrpStartDate,
            DepDate: this.state.rdrpEndDate,

            adults: this.state.gstNumOfAdults,
            chlds: this.state.gstNumOfChildren,
            p_sAges: this.state.gstStringAges,
        };
        //console.log('Header srchparams: ', srchparams);
        axios.post("Hotels/GetHotelPrices", srchparams)
            .then(response => {
                this.setState({ prces: response.data },
                    () => {
                        //console.log('Header srch result SRCHbtn', this.state.prces);
                        //// посылаем данные родителю
                        this.props.func(this.state.prces, this.state.location);
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                // this.setState({
                //   // message: err.res.data.message
                //   // message: err.res.data.title,
                //   message: err.res.data.errors.Email,
                // });
            });
    };

    //#region Пример правильного использования setState для данного проекта    
    ////Код в стиле
    ////    this.setState({value});
    ////    console.log(this.state.value);
    ////не даст желаемого результата, потому что setState() - это асинхронная операция.
    ////Для того чтоб выполнить какой-то код после установки состояния компонента, можно передать callback вторым параметром:
    ////this.setState({value}, () => {
    ////    // вот тут state уже находится в нужном состоянии
    ////    console.log(this.state.value);
    ////  });    
    //setStateExample = (e) => {
    //    console.log('Header before srch this.state.prces: ', this.state.prces);
    //    console.log('Header before tesvariable: ', this.state.tesvariable);
    //    console.log('Header before tesvariable: ', this.state.location);
    //
    //    const srchparams = {
    //        place: e.target.Place.value,
    //        ArrDate: this.state.rdrpStartDate,
    //        DepDate: this.state.rdrpEndDate,
    //        adults: this.state.gstNumOfAdults,
    //        chlds: this.state.gstNumOfChildren,
    //        p_sAges: this.state.gstStringAges,
    //    };
    //    axios.post("Hotels/GetHotelPrices", srchparams)
    //        .then((response) => {
    //            this.setState({
    //                //// сначала устанавливаем значение нашим переменным
    //                prces: response.data,
    //                tesvariable: 'The Data has been set',
    //                location: 'Russia'
    //            }, () => {
    //                //// далее, вторым параметром, при помощи новой функции - вызываем значения уже обновленных переменных
    //                console.log('Header After srch this.state.prces: ', this.state.prces);
    //                console.log('Header After tesvariable: ', this.state.tesvariable);
    //                console.log('Header After tesvariable: ', this.state.location);
    //            })
    //        })
    //}
    //#endregion


    // #region - нужно только для первого рендеринга
    handleSubmitFirst = () => {
        this.props.func(null, this.state.location);

        const srchparams = {
            place: 'Greece',
            ArrDate: convertLocalToUTCDate(new Date(addDays(new Date(), 3))),
            DepDate: convertLocalToUTCDate(new Date(addDays(new Date(), 10))),
            adults: 2,
            chlds: 0,
            p_sAges: '99,99',
        };
        axios.post("Hotels/GetHotelPrices", srchparams)
            .then(response => {
                this.setState({ prces: response.data },
                    () => {
                        //console.log('Header srch result OnLoad: ', this.state.prces);
                        //// посылаем данные родителю
                        this.props.func(this.state.prces, this.state.location);
                    }
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // #endregion - нужно только для первого рендеринга

    componentDidMount() {
        this.nameInput.focus();
        this.handleSubmitFirst();
    }

    render() {
        return (
            <div> {/* TODO: фоновый рисунок это пока временно - подумать потом */}
                <HeaderHead />
                <div
                    style={{
                        // marginTop: '-150px',
                        height: 450,
                        backgroundImage: `url(${Banner02})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}
                ></div>
                    <div className="headerMain" >
                        {/* <div className="headerMain" style={{ backgroundColor: '#ebf1f6' }}> */}
                        <div className="header__center" style={{ marginTop: '5px' }} >
                            <div className="home__section">
                                <form onSubmit={this.handleSubmit}>
                                    <section>
                                        <div>
                                            <input
                                                /* className="inputregion" */
                                                id="Place"
                                                name="Place"
                                                type="text"
                                                placeholder="Country, Region, City or Hotel Name"
                                                ref={(inpt) => {
                                                    this.nameInput = inpt;
                                                }}
                                                value={this.state.location}
                                                onChange={this.setLocation}
                                            />
                                        </div>

                                        <div>
                                            <DateRangePickerShahabyazdi
                                                funck={this.getDataFromDateRangePicker}
                                            />
                                        </div>

                                        <div>
                                            <AddGuests
                                                id="GuestsDropdown"
                                                funk={this.getDataFromAddGuests}
                                            />
                                        </div>

                                        <div>
                                            <button
                                                className="btn-search"
                                                id="btn-search">
                                                Search
                                            </button>
                                        </div>
                                    </section>

                                </form>
                            </div>
                        </div>
                    </div>
            </div>)
    }
}

export default Header
import React from "react";
import "../css/Body.css";
import Pagination from "./body components/Pagination";
import PriceCard from "./body components/PriceCard";

class Body extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPricesPack: []
        };

        this.onPageChanged = this.onPageChanged.bind(this);
    }

    onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        this.setState(() => ({
            currentPricesPack: this.props.myprices.slice(offset, offset + pageLimit)
        })
        )
    }

    render() {
        if (this.props.myprices === null) {
            return (
                <div className="searchPage">
                    <h2 style={{ fontWeight: 'normal', marginTop: '20px', marginBottom: '0px', color: '#39566b' }}><em>Please wait</em></h2>
                    <img src="https://lh3.googleusercontent.com/pw/AM-JKLU0WXiZ0xAexu6wNsYvxy9DJoAXlM-zgfoQATIUaQOwxggeDYpZWJMy_jf3EYYl-2BqN-u_lef-NyBDU3PEDeBLIcQz--BDxA1OPqopzbCTjPtw07tuRgeKigK3TON-bED-4j-C64P6T1E84BO8acrB=w480-h270-no?authuser=0" alt='' />

                    <h2 style={{ fontWeight: 'normal', marginTop: '0px', marginBottom: '20px', color: '#39566b' }}><em>We are looking for the best deals for you</em></h2>
                </div>
            )
        }
        else if (this.props.myprices !== null && this.props.myprices.length !== 0) {
            return (
                <div className="searchPage">
                    <p style={{ marginTop: '10px', marginBottom: '0px' }}>{this.props.myprices.length} properties found for {this.props.location}</p>
                    <div >
                        {this.state.currentPricesPack.map((el) => (
                            <PriceCard key={el.recId} price={el} />
                        ))}
                    </div>
                    <Pagination
                        totalRecords={this.props.myprices.length}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={this.onPageChanged} />
                </div>
            );
        }
        else if (this.props.myprices !== null && this.props.myprices.length === 0) {
            return (
                <div className="searchPage">
                    <div style={{ textAlign: 'center' }}>
                        <h2>No results found</h2>
                        <h2>Try to change the search criteria and try again</h2>
                    </div>
                </div>
            )
        }
        return (<div className="user">
            <h3>Пользователей нет!</h3>
        </div>)
    }
}

export default Body
import React from "react";
import Footer from "./Footer";
import HeaderHead from "./headear components/HeaderHead";
// import '../css/TouristInfo.css'


function TouristInfo() {
    return (
        <div>
            <HeaderHead />
            <div>
                <table
                    align="center" cellpadding="0" cellspacing="0" border="0"
                    style={{
                        fontFamily: 'Rubik, Arial, sans-serif',
                        color: '#4C4C4C',
                        MarginTop: '0',
                        MarginRight: 'auto',
                        MarginBottom: '0',
                        MarginLeft: 'auto',
                        width: '100%',
                        maxWidth: '648px',
                        paddingTop: '20px'
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{
                                fontSize: '21px',
                                lineHeight: '32px',
                                color: '#4C4C4C',
                                fontWeight: '500',
                                textAlign: 'left',
                                paddingBottom: '10px'
                            }}
                            >
                                Overnight Stay Tax:
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" border="0"
                                    style={{
                                        fontFamily: 'Rubik, Arial, sans-serif',
                                        fontSize: '14px',
                                        color: '#4C4C4C',
                                        lineHeight: '20px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ verticalAlign: 'top', fontSize: '16px', lineHeight: '28px' }}
                                            >
                                                <b>What is the Overnight Stay Tax?</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingBottom: '12px' }}>
                                                On the 1st January 2018, the Greek Ministry of Tourism introduced an Overnight Stay Tax, which is aimed at enhancing the state revenue in the context of the current fiscal adjustment programme. This means that tourists are obliged to pay this Overnight Stay Tax.
                                            </td>
                                        </tr>


                                        <tr>
                                            <td
                                                style={{ verticalAlign: 'top', fontSize: '16px', lineHeight: '28px' }}>
                                                <b>Does everyone have to pay this tax?</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingBottom: '12px' }}>
                                                Yes, all guests staying in any standard of accommodation in Greece will have to pay the Overnight Stay Tax.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ verticalAlign: 'top', fontSize: '16px', lineHeight: '28px' }}>
                                                <b>How much is the Overnight Stay Tax?</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingBottom: '12px' }}>
                                                The amount you pay works out between €0.50 and €4.00 per night and will vary depending on the official rating of your accommodation. It is worth bearing in mind, that many UK tour operators set their own star ratings and this often depends on facilities provided. So, on arrival at your chosen hotel, you may find that the star rating differs. You will have to pay the tax as per your accommodation’s rating – GTNO – Greek National Tourist Organisation not your UK tour operators rating.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ verticalAlign: 'top', fontSize: '16px', lineHeight: '28px' }}>
                                                <b>How do I pay the Overnight Stay Tax?</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingBottom: '12px' }}>
                                                You will be asked to pay the Overnight Stay Tax when you arrive and check-in to your accommodation.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ verticalAlign: 'top', fontSize: '16px', lineHeight: '28px' }}>
                                                <b>Does each guest have to pay this tax?</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingBottom: '10px' }}>
                                                No, the charge is per room, per night.
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default TouristInfo
import "./css/App.css";
import React from "react"
import Header from "./components/Header"
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Body from "./components/Body";
import HotelCard from "./components/HotelCard";
import InnerApp from "./InnerApp";
import TestFirst from "./TestFirst";
import TestSecond from "./TestSecond";
import ExAxiosApp from "./components/OLDandTEST/ExAxiosApp";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import Contacts from "./components/Contacts";
import { HashRouter, Routes, Route } from "react-router-dom"; //// https://www.youtube.com/watch?v=ip4Oybl7Rnc
import TouristInfo from "./components/TouristInfo";
import ValidationSimple from "./components/ValidationSimple";
import ValidationCustom from "./components/ValidationCustom";
import ValidationMy from "./components/ValidationMy";
import MaskedPhoneInput from "./components/MaskedPhoneInput";


//// to use (remove previous version installed (react-router-dom@5.2.0)):
//// npm uninstall react-router-dom
//// then (install the newist version)
//// npm i react-router-dom
//// then uncomment below and change file name also
class App extends React.Component {
    // class AppNewBrowserRouter extends React.Component {

    render() {
        return (<div>
            {/* <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<InnerApp />} />

                    <Route path='/hotelCard/:hotel' element={<HotelCard />} />

                    <Route path='/contacts' element={<Contacts />} />
                </Routes>
            </BrowserRouter> */}
            <HashRouter hashType="hashbang">
                <Routes>
                    <Route exact path="/" element={<InnerApp />} />

                    <Route path='/hotelCard/:hotel' element={<HotelCard />} />

                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/touristinfo' element={<TouristInfo />} />
                    <Route path='/validationsimple' element={<ValidationSimple />} />
                    <Route path='/validationcustom' element={<ValidationCustom />} />
                    <Route path='/validationmy' element={<ValidationMy />} />
                    <Route path='/maskedphoneinput' element={<MaskedPhoneInput />} />
                    
                </Routes>
            </HashRouter>
        </div>)
    }
}

export default App
// export default AppNewBrowserRouter
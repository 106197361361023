import React from "react"
import * as ReactDOMClient from 'react-dom/client';
import App from "./App"
import "./index.css"; /* без этого шапка не на всю страницу*/
import axios from "axios";


// axios.defaults.baseURL='https://localhost:44307/api/';
// axios.defaults.baseURL='http://aspnetcoreapi.plivik.com/api/';
axios.defaults.baseURL='https://aspnetcoreapi.plivik.com/api/';
/* axios.defaults.baseURL='https://reqres.in/api/'; */

const app = ReactDOMClient.createRoot(document.getElementById("app"))

app.render(<App />)
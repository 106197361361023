// https://www.youtube.com/watch?v=BGKbJ2aXCog&list=PL6DxKON1uLOHcvuJLmgpOhwbktXLr7noj&index=2
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HeaderHead from "./headear components/HeaderHead";
// import '../css/TouristInfo.css'


const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [maxLengthError, setMaxLengthError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [inputValid, setInputValid] = useState(false)

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'minLength':
                    value.length < validations[validation] ? setMinLengthError(true) : setMinLengthError(false)
                    break;
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true)
                    break;
                case 'maxLength':
                    value.length > validations[validation] ? setMaxLengthError(true) : setMaxLengthError(false)
                    break;
                case 'isEmail':                    
                    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true)
                    break;
            }
        }
    }, [value])

    useEffect(()=>{
        if (isEmpty || maxLengthError || minLengthError || emailError){
            setInputValid(false)
        } else{
            setInputValid(true)
        }
    })

    return {
        isEmpty,
        minLengthError,
        maxLengthError,
        emailError,
        inputValid
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue)
    const [isDirty, setDirty] = useState(false)
    const valid = useValidation(value, validations)
    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid
    }
}

const ValidationCustom = () => {
    const email = useInput('', { isEmpty: true, minLength: 3, isEmail: true })
    const password = useInput('', { isEmpty: true, minLength: 5, maxLength: 8 })

    return (
        <div>
            <form>
                <h1>Registration</h1>
                {(email.isDirty && email.isEmpty) && <div style={{ color: 'red' }}>This field cannot be empty</div>}
                {(email.isDirty && email.minLengthError) && <div style={{ color: 'red' }}>The email must be minimum 3 symbols length</div>}
                {(email.isDirty && email.emailError) && <div style={{ color: 'red' }}>The email you provided is not correct</div>}
                <input onChange={e => email.onChange(e)} onBlur={e => email.onBlur(e)} value={email.value} name='email' type='text' placeholder="Enter your email...." />
                <br />
                {(password.isDirty && password.isEmpty) && <div style={{ color: 'red' }}>This field cannot be empty</div>}
                {(password.isDirty && password.minLengthError) && <div style={{ color: 'red' }}>The password must be minimum 5 symbols length</div>}
                {(password.isDirty && password.maxLengthError) && <div style={{ color: 'red' }}>The password must be maximum 8 symbols length</div>}
                <input onChange={e => password.onChange(e)} onBlur={e => password.onBlur(e)} value={password.value} name='password' type='password' placeholder="Enter your password...." />
                
                <button disabled={!email.inputValid || !password.inputValid} type='button'>Registrtion</button>
            </form>
        </div>
    )
}

export default ValidationCustom

// const ValidationCustom = () => {
//     const [email, setEmail] = useState('')
//     const [password, setPassword] = useState('')
//     const [emailDirty, setEmailDirty] = useState(false)
//     const [passwordDirty, setPasswordDirty] = useState(false)
//     const [emailError, setEmailError] = useState('Email cannot be empty')
//     const [passwordError, setPasswordError] = useState('Password cannot be empty')
//     const [formValid, setFormValid]=useState(false)

//     useEffect(()=>{
//         if(emailError || passwordError){
//             setFormValid(false)
//         } else{
//             setFormValid(true)
//         }
//     },[emailError, passwordError])

//     const BlurHandler = (e) => {
//         switch (e.target.name) {
//             case 'email':
//                 setEmailDirty(true)
//                 break
//             case 'password':
//                 setPasswordDirty(true)
//                 break
//         }
//     }

//     const emailHandler = (e) => {
//         setEmail(e.target.value)
//         const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
//         if (!re.test(String(e.target.value).toLowerCase())) {
//             setEmailError('The email you provided is not correct')
//         } else {
//             setEmailError('')
//         }
//     }

//     const passwordHandler = (e) => {
//         setPassword(e.target.value)
//         if (e.target.value.length < 3 || e.target.value.length > 8) {
//             setPasswordError('The Password must be from 3 to 8 symbols')
//             if(!e.target.value){
//                 setPasswordError('Password cannot be empty')
//             }
//         } else {
//             setPasswordError('')
//         }
//     }

//     return (
//         <div>
//             <form>
//                 <h1>Resistration</h1>
//                 {(emailDirty && emailError) && <div style={{ color: 'red' }}>{emailError}</div>}
//                 <input onChange={e => emailHandler(e)} value={email} onBlur={e => BlurHandler(e)} name='email' type='text' placeholder="Enter your email...." />
//                 {(passwordDirty && passwordError) && <div style={{ color: 'red' }}>{passwordError}</div>}
//                 <input onChange={e => passwordHandler(e)} onBlur={e => BlurHandler(e)} name='password' type='password' placeholder="Enter your password...." />
//                 <button disabled={!formValid} value={password} type='submit'>Registrtion</button>
//             </form>
//         </div>
//     )
// }

// export default ValidationCustom
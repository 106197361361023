import React, { useEffect, useState } from "react"
import "../../css/PriceCard.css";
import SwiperSlider from "./SwiperSlider";
import { Link } from "react-router-dom";
import axios from "axios";
import { color } from "@mui/system";

const default_imgs = [
    'https://lh3.googleusercontent.com/pw/AM-JKLXRF18x9tD2y9S2iJML25bGobc-h5TlzZqgdDpG-mPHkjSBjkv_GUj6jxHCX6DcT2lHrtIuxMSkscTbCHX8YXMwjQJoXWGxY-G13Dfi7Jg9eQLlas-RwWwUjM5oAj2LZ-55ASMpCnRFCnaCiws5RyxB=s626-no?authuser=0'
];

const PriceCard = ({ price }) => {

    const priceLink = '/hotelCard/' + price.hotel;

    //const [cardParams, setCardParams] = useState([]);

    const goToHotelCard = () => {
        //// добавление элемента в local Storage
        localStorage.setItem("priceData", JSON.stringify(price))

        //// route to new page by changing window.location
        // window.open(priceLink, "_blank") //to open new page //// для одностраничного - закоментить и внизу выбрать вариант с LINK
    };

    return (
        <div className="searchResult">
            {price.hph_photoURLs === null ? (
                <SwiperSlider slides={default_imgs}
                    className='searchResult-swiper'
                />
            ) : (
                <SwiperSlider slides={price.hph_photoURLs.split(',')}
                    className='searchResult-swiper'
                />
            )}
            <div className="searchResult__info">
                <div className="searchResult__infoTop">
                    <a href={price.coordinates} target='blank' title='Show on map' className="searchResult__infoTop_p">
                        {price.place}
                    </a>
                    <div className="searchResult__infoTop_div1">
                        <h3>{price.hotel}</h3>
                        <span>{price.coh_stars}</span>
                    </div>
                    <div className="searchResult__infoTop_div2">
                    </div>
                </div>
                <div className="searchResult__infoBottom">
                    <div className="searchResult__infoBottomLeft">
                        <div>
                            <span>Meal Type: </span>
                            <p>{price.pn_Description}</p>
                        </div>
                        <div>
                            <span>Room Type: </span>
                            <p>{price.roomType}</p>
                        </div>
                    </div>
                    {/* react number format for thousands: https://stackoverflow.com/questions/44784774/in-react-how-to-format-a-number-with-commas/44784907 */}
                    <div className="searchResult__infoBottomRight">
                        <p>{price.price.toLocaleString()} {price.rate}</p>
                        <p>{price.arrival}</p>
                        <p>{price.pax}</p>
                        {/* <button onClick={() => goToHotelCard()}>
                            View Deal
                        </button> */}
                        {/* <Link to={priceLink} target='_blank'> */}
                        {/* <Link to={priceLink} target={"_blank"}>
                            <button onClick={() => goToHotelCard()}> View Deal</button>
                        </Link> */}
                        <button onClick={() => goToHotelCard()}>
                            <Link to={priceLink} target={"_blank"} style={{ textDecoration: 'none', color: 'white' }}>
                                View Deal
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceCard
import React from "react"
import "../../css/HeaderHead.css";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import Banner02 from "../../images/Banner02.jpg";
import CallIcon from '@material-ui/icons/Call';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';

/* import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core"; */

class HeaderHead extends React.Component {

    render() {
        return (
            <div className="headerMain"
            /* style={{
                height: 450,
                backgroundImage: `url(${Banner02})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }} */
            >
                <div className="header" style={{ backgroundColor: '#ebf1f6' }}>
                    <div className="header__icon">
                        <Link to="/">
                            <img className="header__icon_img" src={logo} alt="" />
                        </Link>
                    </div>

                    <div className="header__center">
                        <div style={{ display: 'flex', 
                            alignItems: 'center', /* вертикальное выравнивание */
                            justifyContent: 'center', /* горизонтальное выравнивание */
                            marginBottom: '0px' }}>
                            {/* <span style={{ color: '#39566b' }}><b>Contact us 24/7:</b></span>&nbsp;&nbsp;&nbsp; */}
                            {/* <span style={{ color: '#39566b' }}><b>Contact anytime:</b></span>&nbsp;&nbsp;&nbsp; */}
                            <a style={{ color: '#39566b' }} href="tel:+306992324720" target="_blank">
                                <CallIcon />
                            </a>&nbsp;
                            <a style={{ textDecoration: 'none', color: 'black' }} href="tel:+306992324720" target="_blank">
                                Call us
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <a style={{ color: '#39566b' }} href='https://api.whatsapp.com/send?phone=306992324720' target="_blank">
                                <WhatsAppIcon />
                            </a>&nbsp;
                            <a style={{ textDecoration: 'none', color: 'black' }} href='https://api.whatsapp.com/send?phone=306992324720' target="_blank">
                                WhatsApp
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <a style={{ color: '#39566b' }} href='viber://add?number=306992324720' target="_blank">
                                <PhoneInTalkIcon />
                            </a>&nbsp;
                            <a style={{ textDecoration: 'none', color: 'black' }} href='viber://add?number=306992324720' target="_blank">
                                Viber
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                            
                        </div>
                        <div className="home__sectionUpper">
                            <h2 style={{ textAlign: "center", marginTop: 0, color: '#39566b', fontFamily: 'Caveat', fontSize: 40, fontWeight: '800' }}><em>Book Direct Go</em></h2>
                            <h2 style={{ textAlign: "center", marginTop: -35, color: '#39566b', fontFamily: 'Arial', fontSize: 16, fontWeight: '600' }}>Perfect Holidays With Memories</h2>
                        </div>
                    </div>

                    {/* TODO: это пока временно - нужно что-то поставить наверное меню поставлю */}
                    {/* <div className="header__right" style={{ visibility: 'hidden' }}> */}
                    <div className="header__right">
                        {/* <h3 style={{ fontWeight: '400' }}>Home&nbsp;&nbsp;&nbsp;&nbsp;Contact Us&nbsp;&nbsp;&nbsp;&nbsp;About Us&nbsp;&nbsp;&nbsp;&nbsp;</h3> */}
                        {/* <h3 style={{ fontWeight: '400' }}>Contact Us</h3> */}
                        {/* <Link to='/about'>Contact Us</Link> */}
                        {/* <Link to='/contacts' style={{ textDecoration: 'none'}}><h3 style={{ fontWeight: '400', color: 'black', hover:{textDecoration: 'underline'} }}>Contact Us</h3></Link> */}
                        <Link to='/' style={{ textDecoration: 'none' }}><h3 className="menu">Home</h3></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <Link to='/touristinfo' target='_blank' style={{ textDecoration: 'none' }}><h3 className="menu">Tourist info</h3></Link>&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/* <Link to='/contacts' style={{ textDecoration: 'none' }}><h3 className="menu">Contact Us</h3></Link> */}
                        {/* <LanguageIcon />
                <ExpandMoreIcon />
                <Avatar /> */}
                    </div>
                </div>
            </div>)
    }
}

export default HeaderHead
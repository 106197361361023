//// https://shahabyazdi.github.io/react-multi-date-picker/installation/
import React, { useEffect, useState } from "react";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import "../../css/DateRangePickerShahabyazdi.css"; /* ../../../ it would start to look up the path three folder layers above. */
import {addDays, convertLocalToUTCDate} from "../ProgectFunctions";


function CustomInput({ openCalendar, value, dates }) {
  return (
    <div className="DateRangeInput">
    <input type='text'
      value={value + (dates.length ? ` (${dates.length-1} nights)` : "")}
      onFocus={openCalendar}
      placeholder="Check in - Check out"

      // readOnly
      onChange={()=>console.log('changed')}

      required

    />
    </div>
  );
}

function DateRangePickerShahabyazdi(props) {
  // const [dateRange, setDateRange] = useState([null, null]);
  // #region - нужно только для первого рендеринга
  const [dateRange, setDateRange] = useState([new Date(addDays(new Date(),3)), new Date(addDays(new Date(),10))]);
  // #endregion - нужно только для первого рендеринга
  const [startDate, endDate] = dateRange;

  // #region - нужно только для первого рендеринга
  useEffect(() => {
    sendToParent()
  }, [])
  const sendToParent = () => {
    props.funck(
      startDate === null ? null : convertLocalToUTCDate(startDate),//convertLocalToUTCDate(startDate.toDate()),
      endDate === null ? null : convertLocalToUTCDate(endDate)//convertLocalToUTCDate(endDate.toDate())
    )
  }
  // #endregion - нужно только для первого рендеринга

  const handleChange = (update) => {
    setDateRange(update);
  };

  return (
    <div>
      <DatePicker
        id="dateRangePickShakh"
        name="dateRangePickShakh"
        layout='prime'

        calendarPosition='bottom-start'
        /* fixRelativePosition='true' */
        fixrelativeposition='true'

        value={dateRange}
        onChange={handleChange}
        onClose={() =>
          props.funck(
            startDate === null ? null : convertLocalToUTCDate(startDate),
            endDate === null ? null : convertLocalToUTCDate(endDate)
          )
        }
        
        range
        format="ddd. DD MMM."
        render={<CustomInput dates={getAllDatesInRange(dateRange)} />}        
        minDate={addDays(new Date(), 3)}
        numberOfMonths={2}        
        weekStartDayIndex={1}

        //// для нижнего блока
        plugins={[
          <Footer
            position="bottom"
            format="DD MMM. YYYY"
            names={{
              selectedDates: "Stay Dates:",
              from: "In:",
              to: "Out:",
              selectDate: "",
              close: "OK",
              separator: " ",
            }}
          />,
        ]}
        weekDays={["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]}
        months={[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]}
        mapDays={({ date }) => {
          let props = {};
          let isWeekend = [0, 6].includes(date.weekDay.index);

          if (isWeekend) props.className = "highlight highlight-red";

          return props;
        }}
        className="bg-my"
      >
        {/* <button
          type='button'
          onClick={() => setDateRange([null, null])}
        >
          Clear Dates
        </button> */}
      </DatePicker>
    </div>
  );
}

export default DateRangePickerShahabyazdi;

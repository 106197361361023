// import React from "react"
// import { Link } from "react-router-dom";

// import React from "react";


// function TestFirst() {

//     const myData = {
//         name: 'Some thing',
//         price: 123
//       }

//     return (
//         <div>
//             <Link to="/hotelCard/someHotel" state={myData}>Link Text</Link>
//         </div>
//     )
// }

// export default TestFirst


//const TestFirst = () => {
//    const handleClick = () => {
//      const data = {
//        message: 'Hello from the parent window!'
//      };
//      
//      const TestSecond = window.open('/hotelCard/imper', 'New Window');
//      
//      TestSecond.postMessage(data, '*');
//    };
//  
//    return (
//      <div>
//        <h1>Parent Window</h1>
//        <button onClick={handleClick}>Open New Window</button>
//      </div>
//    );
//  };
//
//  export default TestFirst


//// Пример открытие нового окна с передачей данных через local Storage
//// https://stackoverflow.com/questions/47406344/how-to-open-a-page-in-new-tab-on-click-of-a-button-in-react-i-want-to-send-some
import React from "react";

class TestFirst extends React.Component {
    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.state = {projects: [], searchParam : ''};
        this.raiseInvoiceClicked = this.raiseInvoiceClicked.bind(this);
    }
    
    //// Метод для открытия окна и передачи данных в новое окно
    raiseInvoiceClicked(){
        //// добавление элемента в local Storage
        localStorage.setItem("pageOneData", "Parameners from First Page")
        
        //// route to new page by changing window.location
        window.open("/hotelCard/imper", "_blank") //to open new page
     }
    
    render() {
      return (
         <div>
              <div className="container">
                  <div className = "row col-md-4">
                      <h1>Raise Invoice...</h1>
                  </div>
                  <div className = "row col-md-4"></div>
                  <div className = "row col-md-4" style ={{"marginTop":"24px"}}>
                      <button type="button" onClick={this.raiseInvoiceClicked}>Raise Invoice</button>
                  </div>
                  
              </div>
         </div>
      )
    }
}

  export default TestFirst